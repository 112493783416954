export enum Direction {
    horizontal = 'horizontal',
    vertical = 'vertical'
}

export enum Size {
    small = 'small',
    big = 'big'
}

export enum Accent {
    primary = 'primary',
    secondary = 'secondary',
    transparent = 'transparent'
}

export enum ButtonType {
    linkInternal = 'linkInternal',
    linkExternal = 'linkExternal',
    submit = 'submit',
    custom = 'custom'
}

export enum ResourcesGroupField {
    type = 'type',
    platform = 'platform',
    topic = 'topic'
}

export default { Direction, Size, Accent, ButtonType };
